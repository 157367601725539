@import 'https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css';
@import 'https://use.fontawesome.com/releases/v5.8.1/css/all.css';
@import 'https://fonts.googleapis.com/css?family=Open+Sans:400,700';
body {
  font-family: 'Open Sans', sans-serif;
  background-color: #F2F2F2;
}

body h1 {
  font-size: 3em;
  color: #F2F2F2;
  font-weight: bold;
}

body p {
  color: #222222;
}

body .bold {
  font-weight: bold;
}

body strong, body strong a {
  color: #1E639A;
}

.error_alert {
  z-index: 9999;
  top: 60px;
}

.header {
  padding: 40px;
  background-image: url("/assets/images/home_mobile.jpg");
  background-position: center;
  background-size: cover;
  height: 800px;
}

@media (min-width: 768px) {
  .header {
    background-image: url("/assets/images/home.jpg");
    background-position: right center;
  }
}

.header h1 {
  color: #F2F2F2;
  font-size: 4em;
  margin-top: 1.5em;
  text-align: center;
  font-weight: bold;
}

@media (min-width: 768px) {
  .header h1 {
    text-align: right;
    font-size: 5em;
    margin-top: 1.5em;
    color: #F2F2F2;
  }
}

.header p {
  color: #F2F2F2;
  text-align: center;
  font-size: 1.2em;
  margin-bottom: 1em;
}

@media (min-width: 768px) {
  .header p {
    text-align: right;
    color: #F2F2F2;
    font-size: 1.5em;
    margin-bottom: 1em;
  }
}

.header .call-to-action {
  text-align: center;
}

@media (min-width: 768px) {
  .header .call-to-action {
    text-align: right;
  }
}

.header .call-to-action .btn {
  margin-top: 10px;
}

.header .logo {
  height: 100px;
  -webkit-filter: invert(1);
          filter: invert(1);
}

.header .menu ul, .header .menu li {
  margin: 0;
  padding: 0;
}

.header .menu li {
  list-style: none;
  display: inline-block;
}

.header .menu a {
  display: inline-block;
  line-height: 2em;
  padding: 10px;
}

.main {
  padding: 20px;
}

.main h2 {
  color: #222222;
  margin: 20px 0;
  text-transform: uppercase;
  font-size: 2em;
  text-align: left;
}

.main h3, .main .product-icon {
  color: #222222;
}

.main h3 {
  font-weight: normal;
  color: #1E639A;
}

.main h4, .main h5 {
  font-weight: bold;
  font-size: .8em;
}

@media (min-width: 768px) {
  .main h4, .main h5 {
    font-size: 1.2em;
  }
}

.main .product-icon {
  color: #1E639A;
  margin-bottom: 20px;
  font-size: 5em;
}

.main .separator {
  margin: 40px;
}

.main label {
  color: #222222;
}

.footer {
  background-color: #1E639A;
  color: #F2F2F2;
  padding: 20px;
}

.footer .logo {
  height: 25px;
  -webkit-filter: invert(1);
          filter: invert(1);
}

.footer p {
  font-size: .9em;
  color: #F2F2F2;
  line-height: 1.5em;
  margin: 0;
  text-align: left;
}

@media (min-width: 768px) {
  .footer p {
    text-align: left;
  }
}

.btn-primary, .btn-primary:focus {
  background-color: #1E639A;
  border-color: #1C6AAA;
}

.btn-primary:hover {
  background-color: #1E639A;
  border-color: #1C6AAA;
}

.navbar-brand {
  -webkit-filter: invert(1);
          filter: invert(1);
}

.navbar.bg-dark {
  background-color: #1E639A !important;
}
